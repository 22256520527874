.loading-wave-wrapper {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.circle {
    border-radius: 100%;
    background-color: var(--color-text);
}