.webcam {
    grid-column: 1;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
}

.loading {
    background-color: var(--color-background);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    z-index: 5;
}

.partial-overlay {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
}

.full-overlay {
    grid-column: 1;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.camera-canvas {
    z-index: 1;
}

.detection-canvas {
    z-index: 2;
}

.mask {
    z-index: 3;
}

.status-tiles {
    grid-column: 1;
    grid-row: 1 / 2;
    z-index: 6;
    width: 90vmin;
    height: 4.5rem;
    display: flex;
    flex-direction: row;
    max-width: 480px;
    align-self: start;
    justify-content: space-evenly;
    align-items: end;
}

.tile {
    width: 160px;
    height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #8B3131;
    background-color: #DA8D8D;
    border-radius: 100px;
    border: none;
}

.tile.success {
    color: #238721;
    background-color: #8EDA8D;
}

.tile-title {
    font-size: 12px;
    font-weight: var(--font-weight-regular);
}

.tile-info {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
}

.flash {
    z-index: 15;
    animation: cameraFlash .1s ease-in forwards;
}

.white-text {
    color: white;
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-semi-bold);
}


.scan-interim-text {
    color: var(--color-text);
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-semi-bold);
}

.scan-grid-bottom-bg {
    grid-row: 2 / 4;
    grid-column: 1;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 95%);
}

.scan-grid-bottom {
    grid-row: 3 / 4;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    z-index: 10;
}

.play-icon-wrapper {
    grid-row: 1;
    grid-column: 1;
    height: 100%;
    width: 100%;
    position: relative;
}

.tutorial-play-icon {
    color: white;
    filter: drop-shadow(0px 2px 4px rgba(100,100,100,0.6));
    opacity: 1;
    position: absolute;
    top: 5%;
    right: 10%;
    animation: pulsePlay .6s linear alternate-reverse infinite;
    z-index: 1;
    font-size: 25px;
}

/* Head Initialize Style */
.head-initialize {
    grid-column: 1;
    grid-row: 1 / 4;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 75% 10% 15%;
    grid-template-columns: 1fr;
    background-color: var(--color-background);
}

.head-initialize-text {
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    place-content: center;
    row-gap: 14px;
    max-width: 266px;
    color: var(--color-text);
    font-size: 1rem;
    font-weight: var(--font-weight-semi-light);
    text-align: center;
}

.head-initialize-heading {
    margin-left: auto;
    margin-right: auto;
    max-width: 248px;
    color: var(--color-text);
    font-size: 1.5rem;
    font-weight: var(--font-weight-semi-bold);
    text-align: center;
}

.head-initialize-button-top {
    display: grid;
    justify-content: center;
    align-self: end;
    width: 100%;
    max-width: 480px;
    padding-bottom: 1rem;
}

.head-initialize-button-bottom {
    display: grid;
    justify-content: center;
    align-self: start;
    width: 100%;
    max-width: 480px;
}

/* Head Scan Style */
.head-scan-img {
    margin-top: 30%;
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    place-items: center;
    z-index: 2;
}

.head-scan-tutorial-background {
    grid-row: 1 / 4;
    grid-column: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: black;
    opacity: 0.7;
    transition: opacity 0.1s ease-in;
}

.head-scan-tutorial-background.hidden {
    opacity: 0;
}

.head-scan-text-container {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 10px;
    font-size: 1.5em;
    z-index: 2;
}

.head-scan-interact {
    grid-row: 3;
    width: 80%;
    height: 100%;
    grid-column: 1;
    display: grid;
    align-items: start;
    z-index: 2;
}

.round-capture-button {
    justify-self: center;
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border: 6px solid lightgray;
    border-radius: 2px;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    background-color: white;
    z-index: 2;
}

.head-tutorial-buttons {
    width: 100%;
    height: 15vmin;
    max-width: 480px;
    max-height: 48px;
    display: grid;
    grid-template-columns: 35% calc(65% - 10px);
    column-gap: 10px;
    opacity: 0;
    transform: translateY(15px);
    transition: transform .6s linear, opacity .5s linear;
}

.head-tutorial-buttons.show-up-buttons {
    opacity: 1;
    transform: translateY(0px);
  }

.head-tutorial-back-button {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(0,0,0,0);
    border: 1px solid white;
    color: white;
    font-size: 1rem;
    font-weight: 700;
}

.head-tutorial-start-button {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 12px;
    background-color: white;
    color: black;
    font-size: 1rem;
    font-weight: 700;
    flex-grow: 1;
}

.head-scan-text {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    max-width: 230px;
}

@keyframes cameraFlash {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    50% {
        background-color: rgba(255, 255, 255, 0.9);
    }
    100% {
        background-color: rgba(255, 255, 255, 0);
    }
}

@keyframes pulsePlay {
    100% {
        opacity: 0.4;
    }
}