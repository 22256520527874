html {
        /* Font sizes */
        --font-size-logo: 1.75rem;
        --font-size-title: 1.25rem;
        --font-size-subtitle: 1rem;
        --font-size-normal: 0.75rem;
        --font-size-small: 0.5rem;
    
        /* Font weights */
        --font-weight-light: 300;
        --font-weight-semi-light: 400;
        --font-weight-regular: 600;
        --font-weight-semi-bold: 700;
        --font-weight-bold: 800;
    
        /* Font colors */
        --color-text: #000;
        --color-background: #f9f9f9;
        --color-primary-button: #FB4F39;
        --color-secondary-button: #FB4F39;
        --color-disabled-button: #DDD;
        --color-primary-button-text: #fff;
        --color-secondary-button-text: #000;
        --color-disabled-button-text: #808080;

        /* Navigation button style */
        --nav-button-border-radius: 100px;
    }