.styled-button {
  width: min(80vmin, 480px);
  height: min(15vmin, 48px);
  border: none;
  text-align: center;
  font-size: var(--font-size-sub-title);
  font-weight: var(--font-weight-semi-bold);
  border-radius: 12px;
}

.border-nav {
  border-radius: var(--nav-button-border-radius);
}

.border-smooth {
  border-radius: 100px;
}

.hidden-button {
  opacity: 0;
  transform: translateY(15px);
  transition: transform .6s linear, opacity .5s linear;
}

.slide-up {
  opacity: 1;
  transform: translateY(0px);
}

.white-background {
  background-color: #fff;
  color: #000;
}

.primary-background {
  background-color: var(--color-primary-button);
  color: var(--color-primary-button-text);
  transition: background-color 0.3s ease;
}

.secondary-background {
  background-color: transparent;
  border: 1px solid var(--color-secondary-button);
  color: var(--color-secondary-button-text);
  transition: background-color 0.3s ease;
}

.styled-button:disabled {
  background-color: var(--color-disabled-button);
  color: var(--color-disabled-button-text);
}
