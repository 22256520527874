.product-recommend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    padding: 1rem 2rem 1rem;
    text-align: left;
    background-color: #f3f3f3;
}

.logo-bar {
    display: flex;
    justify-content: center;
    place-items: center;
    position: relative;
    width: 100%;
    max-width: 480px;
    height: 100px;
}

.img-container {
    width: 50%;
    height: 100%;
    min-width: 128px;
}
.logo-img {
    width: 50%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: darken;
}

.back-icon {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: none;
    color: black;
    background-color: none;
    padding: 5px;
    z-index: 1;
}

.product-individual {
    width: 100%;
    max-width: 480px;
}

.page-info {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    color: var(--color-text)
}

.title {
    font-size: 1rem;
    font-weight: 600;
}

.products-title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-text)
}

.products-text {
    padding: 0;
    font-size: 0.75rem;
    font-weight: 600;
    color: #808080;
}

.products-category-wrapper {
    width: 100%;
    max-width: 480px;
}