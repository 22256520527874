.home {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 15% 15%;
    place-items: center;
    background-color: var(--color-background);
    color: var(--color-text)
}

.info-text {
    grid-row: 2 / 3;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    z-index: 2;
}

.home-button-wrapper {
    width: 80%;
    height: 100%;
    grid-row: 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
}

.validate-container {
    width: 80%;
    height: 100%;
    max-width: 500px;
    grid-column: 1;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.validate-options-container {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.25rem;
}

.validate-icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 1px solid var(--color-text);
    display: flex;
    justify-content: center;
    align-items: center;
}

.validate-option {
    width: 200px;
    min-height: 64px;
    display: flex;
    align-items: center;
    gap: .75rem;
    white-space: pre-line;
}

.validate-icon {
    color: var(--color-text);
}

.animate-mark {
    animation: fill-bg-in-out .5s ease-out forwards;
}

.license-option {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
}

.terms {
    justify-content: flex-end;
}

.title-text {
    color: var(--color-text);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
}

.home-sub-title {
    padding-top: 0.75rem;
    font-size: var(--font-size-sub-title);
    color: var(--color-text);
}

.validate-title {
    color: var(--color-text);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
}

.validate-sub-title {
    color: var(--color-text);
    font-size: var(--font-size-sub-title);
    font-weight: var(--font-weight-semi-light);
    padding-top: .5rem;
}

.normal-text {
    padding: 0;
    color: var(--color-text);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-semi-bold);
}

.home-loading-page {
    grid-row: 1 / 4;
    grid-column: 1;
    background-color: var(--color-background);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.loading-text {
    color: var(--color-text);
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-semi-bold);    
}

.language-selector {
    position: absolute;
    top: 5%;
    right: 10%;
}

.customer-logo-container {
    grid-row: 1 / 2;
    grid-column: 1;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.desktop-info {
    grid-row: 1 / 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 312px;
}

.desktop-title {
    font-size: var(--font-size-logo);
    color: var(--color-text);
    font-weight: var(--font-weight-semi-bold);
}


.desktop-detail {
    font-size: var(--font-size-subtitle);
    color: var(--color-text);
    font-weight: var(--font-weight-semi-light);
}

.source-logo {
    grid-row: 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 312px;
}

.source-text {
    font-size: var(--font-size-normal);
    color: var(--color-text);
    font-weight: var(--font-weight-semi-light);    
}

@keyframes fill-bg-in-out {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: var(--color-text);
    }
    100% {
        background-color: transparent;
    }
}