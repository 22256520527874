.error-page {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 85% 15%;
    place-items: center;
}

.error-text-wrapper {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.error-icon {
    width: 30vw;
    height: 30vw;
    max-width: 200px;
    max-height: 200px;
    color: var(--color-text);
}

.error-page-title {
    margin-top: 3rem;
    color: var(--color-text);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
    max-width: 273px;
    opacity: 0;
    animation: textReveal .6s ease-in forwards;
}

.error-page-info {
    color: var(--color-text);
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-semi-light);
    max-width: 273px;
    text-align: center;
    opacity: 0;
    animation: textReveal .6s ease-in forwards;
}

.error-page-button {
    grid-row: 2;
    grid-column: 1;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;  
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}