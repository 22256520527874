.product-cards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.product-card {
    display: flex;
    gap: 0.75rem;
    padding: 12px 0px 12px;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    cursor: pointer;
}

.product-img-container {
    width: 110px;
    height: 110px;
    display: flex;
    place-items: center;
    justify-content: center;
}

.product-img {
    min-width: 110px;
    min-height: 110px;
    object-fit: contain;
    mix-blend-mode: darken;
}

.product-text {
    display: flex;
    flex-direction: column;
}

.product-title {
    padding: 0;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    text-transform: uppercase;
    color: var(--color-text);
}

.product-details {
    padding-top: 0.5rem;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-text);
}

.product-price {
    padding-top: 0.5rem;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-text);
}

.product-link {
    padding-top: 0.5rem;
    font-size: var(--font-size-normal);
    font-family: "Nunito";
    font-weight: var(--font-weight-normal);
    color: var(--color-text);
    text-decoration: underline;
}