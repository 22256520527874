.result {
    width: 100%;
    display: grid;
    background-color: var(--color-background);
    scroll-behavior: smooth;
    padding-bottom: 1rem;
}

.result-short-info {
    height: 100svh;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 70% 20% 5% 5%;
    background-color: var(--color-background);
}

.text-info-background {
    width: 100%;
    height: 100%;
    grid-row: 2 / 5;
    grid-column: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);
    z-index: 1;
}

.results-image {
    grid-row: 1 / 5;
    grid-column: 1;
    position: relative;
    height: 100%;
    width: 100%;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.top-icons {
    position: absolute;
    top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 100%;
}

.close-icon {
    position: absolute;
    right: 10%;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: none;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    z-index: 2;
    opacity: 0;
    animation: textReveal 1s ease-in forwards;
}

.copy-button {
    width: fit-content;
    height: 32px;
    border-radius: 100px;
    border: none;
    color: black;
    background-color: #FB4F39;
    padding: 8px 16px;
    z-index: 2;  
}

.copy-button.success {
    color: #fff;
    background-color: #8BB557;
}

.more-info-icon {
    grid-row: 4;
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.arrow-down {
    color: white;
    font-size: 1.5rem;
}

.results-info {
    grid-row: 2 / 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    z-index: 2;
}

.result-type {
    font-size: 0.75rem;
    font-weight: 700;
    color: white;
}

.result-decision {
    height: 3rem;
    font-size: 1.5rem;
    color: white;
}

.result-decision.red {
    color: #D74238;
}

.result-button {
    width: auto;
    padding: 10px 24px;
    height: 2rem;
    border-radius: 100px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    white-space: nowrap;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    color: white;
}

.result-button.selected {
    background-color: white;
    color: black;
    border: 1px solid black;
    text-wrap: nowrap;
    white-space: nowrap;
}

.result-button.green {
    background-color: black;
    color: white;
    text-wrap: nowrap;
    white-space: nowrap;
}

.recommend-button.selected {
    background-color: white;
    filter: drop-shadow(0px 2px 4px rgba(100,100,100,0.6));
    opacity: 1;
    animation: slowFadePulse 1s linear alternate-reverse infinite;
    margin-bottom: 1rem;
    color: black;
    border: none;
    text-wrap: nowrap;
    white-space: nowrap;
    width: fit-content;
    padding: 10px 24px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.result-types-button-wrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    overflow-x: scroll;
    scrollbar-width: none;
}

.result-types {
    grid-row: 3;
    grid-column: 1;
    width: 100%;
    z-index: 2;
    display: grid;
    justify-content: center;
}

.result-types-button-wrapper::-webkit-scrollbar {
    display: none;
}

.result-detail-info {
    width: 100%;
    padding: 1rem 2rem 1rem;
    background-color: #f3f3f3;
    text-align: left;
    white-space: none;
    display: grid;
    justify-content: center;
}

.result-detail-text {
    line-height: 1.5;
    text-align: left;
    color: black;
    font-size: 0.9rem;
    width: 100%;
    max-width: 480px;
    white-space: pre-line;
}

.hair-colors {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hair-color-bar {
    padding: 10px 0.4rem 0;
    display: flex;
    align-self: center;
    align-items: center;
    gap: 0.4rem;
    height: 3rem;
    width: 80%;
    max-width: 440px;
}

.average-hair-color-item {
    border: 1px solid white;
    border-radius: 100px;
    height: 1rem;
    flex: 2;
}

.hair-color-item{
    border: 1px solid white;
    border-radius: 100px;
    height: 1rem;
    flex: 1;
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}

@keyframes textSlideLeft {
    100% {
        right: 15%;
    }
}

@keyframes textSlideRight {
    100% {
        right: 70%;
    }
}

@keyframes slowFadePulse {
    100% {
        opacity: 0.6;
    }
}