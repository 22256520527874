.email-content {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 80%;
}

.email-info-wrapper {
    width: 100%;
    height: 100%;
    text-align: left;
    grid-row: 1 / 2;
    grid-column: 1;
    font-size: 1em;
}

.email-title {
    color: var(--color-text);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
}

.email-info {
    padding-top: 10px;
    color: var(--color-text);
    font-size: var(--font-size-normal);
}

.email-input-wrapper {
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 1;
    display: grid;
    place-items: center;
}

.email-input-field {
    width: 100%;
    height: 3rem;
    background-color: transparent;
    border: 1px solid var(--color-text);
    border-radius: 12px;

    color: var(--color-text);
    font-size: 16px;
    font-weight: var(--font-weight-normal);
    font-family: "Nunito";
    padding-left: 1rem;
    padding-top: 14px;
    padding-bottom: 14px;
}

.email-input-field:focus {
    outline: none;
}

.email-input-field::placeholder {
    color: var(--color-text);
}