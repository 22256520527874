.language-dropdown {
    width: 90px;
    background-color: transparent;
    text-transform: uppercase;
    text-align: right;
    color: #212121;
    position: relative;
}

.language-menu {
    width: 100%;
    max-height: 140px;
    position: absolute;
    background-color: white;
    list-style-type: none;
    border-radius: 12px;
    top: 0%;
    right: 0%;
    display: inline-block;
    overflow-y: scroll;
}

.language-menu {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.language-menu::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.language-item {
    width: 100%;
    height: 46px;
    display: flex;
    text-align: right;
    align-items: center;
    background-color: inherit;
    justify-content: space-between;
    padding: 14px 16px;
    color: black;
    font-size: 1rem;
    font-weight: 700;
}

.language-item.selected {
    width: 100%;
    justify-content: flex-end;
}

.language-item.hidden {
    opacity: 0;
}

.flag-circle {
    border-radius: 100%;
    font-size: 24px;
}