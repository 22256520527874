.questions-module {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 85% 0% 0% 0% 15%;
    place-items: center;
    transition: grid-template-rows .5s ease-in-out;
}

.five-row-grid {
    grid-template-rows: 20% 10% 10% 45% 15%;
}

.analyze-progress {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.analyze-title-success {
    margin-top: 3.5rem;
    color: var(--color-text);
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
    max-width: 273px;
    opacity: 0;
    animation: textReveal 1.2s ease-in forwards;
}

.analyze-info-success {
    color: var(--color-text);
    font-size: var(--font-size-subtitle);
    font-weight: var(--font-weight-semi-light);
    max-width: 273px;
    text-align: center;
    opacity: 0;
    animation: textReveal 1.2s ease-in forwards;
}

.analyze-text-animated {
    font-size: var(--font-size-subtitle);
    color: var(--color-text);
    font-weight: var(--font-weight-regular)
}

.result-ready-wrapper {
    padding: 5px;
    width: 30%;
    height: 30%;
}

.result-ready-animaton {
    width: 30vw;
    height: 30vw;
    max-width: 200px;
    max-height: 200px;
    color: var(--color-text);
}

.result-ready-animaton path {
    stroke-dasharray: 138;
    stroke-dashoffset: 138;
    animation: revealTick .4s ease-in-out forwards;
}

.question-module-content {
    grid-row: 3 / 5;
    grid-column: 1;
    width: 80%;
    max-width: 480px;
    height: 100%;
    display: grid;
    place-items: center;
    overflow-y: auto;
    scrollbar-width: none;
}

.question-module-content.full {
    grid-row: 2 / 5;
}

.question-module-btn-wrapper {
    grid-row: 5;
    grid-column: 1;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;  
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}

@keyframes revealTick {
    100% {
        stroke-dashoffset: 276;
    }
}

.pulse-animation-conatiner {
    width: 100%;
    height: 30vmin;
    max-width: 100%;
    max-height: 200px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}