.checkbox {
    width: 24px;
    height: 24px;
    margin: 8px;
    border-radius: 7px;
    border: 2px solid var(--color-text);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.checkmark {
    width: 12px;
    height: 12px;
    color: var(--color-text);
}