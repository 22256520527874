.form-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    row-gap: .5rem;
}

.form-content-option {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    max-width: 480px;
    display: flex;
    color: var(--color-text);
}


.form-content-text {
    grid-row: 2 / 3;
    grid-column: 1;
    width: 80%;
    max-width: 480px;
    height: 100%;
    display: grid;
    text-align: left;
    color: var(--color-text);
}

.form-input-field {
    width: 100%;
    height: 5 * var(--font-size-normal);
    background-color: transparent;
    border: 1px solid var(--color-text);
    border-radius: 12px;

    color: var(--color-text);
    font-size: 16px;
    font-weight: var(--font-weight-normal);
    font-family: "Nunito";
    padding: 14px 16px 14px;
}

.form-input-field:focus {
    outline: none;
}

.form-input-field::placeholder {
    color: var(--color-text);
}

.form-input-limit {
    padding-top: 0;
    color: var(--color-text);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    opacity: 0.6;
}