.overlay-circles {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.percentage-circle {
    width: 70px;
    height: 70px;
    position: absolute;
}

.position-0 {
    top: 20%;
    right: -20%;
    animation: textSlideLeft .6s ease-in-out forwards;
}

.position-1 {
    top: 65%;
    right: 105%;
    animation: textSlideRight .7s ease-in-out forwards;
}

.position-2 {
    top: 70%;
    right: -20%;
    animation: textSlideLeft .8s ease-in-out forwards;
}


.percentage-value {
    font-size: 0.75rem;
    font-weight: 700;
    color: black;
    text-align: center;
    opacity: 0;
    animation: textReveal 0.7s ease-in forwards;
}

.category-text {
    font-size: 0.6rem;
    font-weight: 700;
    color: black;
    text-align: center;
    opacity: 0;
    animation: textReveal 0.7s ease-in forwards;
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}

@keyframes textSlideLeft {
    100% {
        right: 15%;
    }
}

@keyframes textSlideRight {
    100% {
        right: 70%;
    }
}